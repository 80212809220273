class cgThemeMode {
    constructor() {
        this.prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;

        // Select all elements with the class .theme-switch
        this.toggleSwitches = document.querySelectorAll(".theme-switch input[type='checkbox']");

        this.cookieTheme = getCookie("theme") !== 'undefined' ? getCookie("theme") : '';

        this.theme = this.cookieTheme || (this.prefersDarkMode ? "dark" : "light");
    }

    getActiveThemeMode(){
        if(this.cookieTheme && ['dark', 'light'].includes(this.cookieTheme)){
            return this.cookieTheme;
        }

        let theme = this.prefersDarkMode ? 'dark' : 'light'
        this.setTheme(theme);
        return theme;
    }

    setTheme(themeType) {
        document.documentElement.dataset.theme = themeType;
        setCookie("theme", themeType, 365);
    }

    btnSetTheme() {
        // Toggle the theme
        const newTheme = document.documentElement.dataset.theme === 'dark' ? 'light' : 'dark';
        this.setTheme(newTheme);

        // Update the checked state of toggle switches
        this.toggleSwitches.forEach(toggleSwitch => {
            toggleSwitch.checked = newTheme === 'dark';
        });
    }

    init() {
        console.log('this.getActiveThemeMode', this.getActiveThemeMode())
        if(this.getActiveThemeMode() === 'dark'){
            // Loop through all toggleSwitches and set their checked property
            this.toggleSwitches.forEach(toggleSwitch => {
                toggleSwitch.checked = true;
            });
        }

        // Bind click event to all toggleSwitches
        document.querySelectorAll('.theme-switch input[type="checkbox"]').forEach(toggleSwitch => {
            toggleSwitch.addEventListener('click', this.btnSetTheme.bind(this));
        });
    }
}

$(function () {
    var cgTheme = new cgThemeMode();
    window.cgTheme = cgTheme;
    cgTheme.init();
});
